var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Parent',[_c('template',{slot:"content"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_c('v-row',{attrs:{"align":"center"}},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"text-capitalize font-weight-regular",attrs:{"color":"primary","text":"","to":"/faq/create"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t('page_faq_create_title'))+" ")],1)],1)],1)],1),_c('v-card-text',{staticClass:"px-0"},[(_vm.pagination && _vm.faqs)?_c('Table',{attrs:{"headers":_vm.headers,"items":_vm.faqs,"loading":_vm.loading,"total-records":_vm.pagination.records_total,"number-of-pages":_vm.pagination.total_pages},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(item.id))])]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.category.name))])]}},{key:"item.question",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.question))])]}},{key:"item.answer",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.answer))])]}},{key:"item.order",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.order))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"secondary","text":"","to":("/faq/" + (item.id) + "/edit")}},[_c('v-icon',{attrs:{"size":"12"}},[_vm._v("$edit")])],1),_c('v-icon',{on:{"click":function($event){_vm.deleteItem = item
                  _vm.deleteItemDlg = true}}},[_vm._v(" mdi-delete ")])]}}],null,false,3655392181)}):_vm._e()],1)],1)],1),_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"500"},model:{value:(_vm.deleteItemDlg),callback:function ($$v) {_vm.deleteItemDlg=$$v},expression:"deleteItemDlg"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v(" "+_vm._s(_vm.$t('confirmation'))+" ")]),_c('v-card-text',[_c('v-row',{staticClass:"text-h7 pa-3 pt-7"},[_vm._v(" "+_vm._s(_vm.$t('page_faqs_edit_confirmation_message'))+" ")])],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"mr-3",attrs:{"disabled":_vm.formStatus,"color":"primary"},on:{"click":_vm.deleteItemConfirm}},[_vm._v(_vm._s(_vm.$t('confirm')))]),_c('v-btn',{attrs:{"disabled":_vm.formStatus,"color":"red","dark":""},on:{"click":function($event){_vm.deleteItemDlg = false}}},[_vm._v(_vm._s(_vm.$t('cancel')))])],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }